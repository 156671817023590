<template>
  <div>
    <template v-for="(option_answer, i) in optionAnswerSingleChoice">
      <v-row :key="i">
        <v-col cols="1" class="p-0 text-right">
          <p class="text-h6 mt-4" style="color: #6495ed">
            {{ i | getAlphabetCharacterFromIndex }}.
          </p>
        </v-col>
        <v-col cols="9" class="pt-0 pb-0">
          <!--          <EditorBalloon min-height="20" v-model="option_answer.option" disabled placeholder="Nhâp câu trả lời"></EditorBalloon>-->
          <v-text-field
            placeholder="Nhâp câu trả lời"
            outlined
            dense
            v-model="option_answer.option"
          ></v-text-field>
        </v-col>
        <v-btn icon color="grey" large @click="addOptionSingleChoice">
          <v-icon>mdi-plus-circle-outline</v-icon>
        </v-btn>
        <v-btn icon color="grey" large @click="deleteOptionSingleChoice(i)">
          <v-icon>mdi-minus-circle-outline</v-icon>
        </v-btn>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SingleChoiceType",
  components: {
    // EditorBalloon: () => import("../../../../components/ckeditor5/EditorBalloon"),
  },
  props: {
    option: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState({
      option_groups: (state) => state.surveyQuestionStore.option_groups,
    }),
    optionAnswerSingleChoice: {
      get() {
        return this.option;
      },
      set(value) {
        this.$emit("setOptionAnswer", value);
      },
    },
  },
  methods: {
    addOptionSingleChoice() {
      if (this.optionAnswerSingleChoice.length > 10) {
        this.$toasted.error("Chỉ tạo được tối đa 10 option !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      let data = {
        option: "",
      };
      this.optionAnswerSingleChoice.push(data);
    },
    deleteOptionSingleChoice(i) {
      if (this.optionAnswerSingleChoice.length <= 1) {
        this.$toasted.error("Phải có tối thiểu 1 option !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      this.optionAnswerSingleChoice.splice(i, 1);
    },
  },
};
</script>

<style scoped></style>
